import React from "react";
import SendMessage from "./SendMessage";
// import ScheduleMessage from "./ScheduleMessage";

const Messages = () => {
  return (
    <>
      <div>
        {/* <ScheduleMessage /> */}
        <SendMessage />
      </div>
    </>
  );
};

export default Messages;
