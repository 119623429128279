import React, { useEffect, useState } from "react";
import {
  getAllGroups,
  getAllGroupsets,
  deleteGroupset,
} from "../../services/service";
import { Button, Table, Spin, Input } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import showAlert from "../../components/showAlert";
import ModifyGroupset from "./ModifyGroupset";
import showConfirm from "../../components/showConfirm";

const ListGroupsets = () => {
  // ----------------------------- State -------------------------------
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupsets, setGroupsets] = useState([]);
  const [filteredGroupsets, setFilteredGroupsets] = useState([]);
  const [searchText, setSearchText] = useState("");

  // ----------------------------- Fetch Data -------------------------------
  useEffect(() => {
    getGroups();
    getGroupsets();
  }, []);

  const getGroups = async () => {
    setLoading(true);
    const data = await getAllGroups();
    setGroups(data || []); // Fallback to an empty array if data is null or undefined
    setLoading(false);
  };

  const getGroupsets = async () => {
    setLoading(true);
    const data = await getAllGroupsets();
    setGroupsets(data || []); // Fallback to an empty array if data is null or undefined
    setFilteredGroupsets(data || []); // Initialize filtered data
    setLoading(false);
  };

  // ----------------------------- Search Functionality -------------------------------
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    const filtered = groupsets.filter((groupset) =>
      groupset.name.toLowerCase().includes(value)
    );
    setFilteredGroupsets(filtered);
  };

  // ----------------------------- Table -------------------------------
  const columns = [
    {
      title: "S.No.",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Groups",
      key: "groups",
      render: (record) => (
        <div
          style={{
            maxHeight: "80px",
            overflowY: "auto",
            padding: "5px",
            border: "1px solid #f0f0f0",
            borderRadius: "5px",
          }}
        >
          {(record.groups || []).map((group) => (
            <span
              key={group.groupid}
              style={{
                background: "#f9f9f9",
                padding: "5px",
                margin: "5px 0",
                display: "block",
                borderRadius: "3px",
              }}
            >
              {group.groupname}
            </span>
          ))}
        </div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            type="primary"
            onClick={() => openModal("update", record)}
            icon={<EditOutlined />}
          />
          <Button
            type="primary"
            danger
            onClick={() => delete_button_on_click(record)}
            icon={<DeleteOutlined />}
          />
        </div>
      ),
    },
  ];

  const dataSource = filteredGroupsets.map((groupset, index) => ({
    key: groupset._id, // Unique key for each row
    ...groupset,
    index: index + 1,
  }));

  // ----------------------------- Modal -------------------------------
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [flag, setFlag] = useState("save");
  const [params, setParams] = useState({});

  const openModal = (flag, params) => {
    setFlag(flag);
    if (flag === "save") {
      setParams({});
    } else {
      const obj = {
        _id: params._id,
        id: params.id,
        name: params.name,
        groups: params.groups || [], // Fallback to an empty array
      };
      setParams(obj);
    }
    setIsOpenModal(true);
  };

  const closeModal = async (result) => {
    if (result) await getGroupsets();
    setIsOpenModal(false);
  };

  // ----------------------------- Delete Button -------------------------------
  const delete_button_on_click = async (groupset) => {
    const confirm = await showConfirm(
      "Confirmation",
      "Are you sure, you want to remove it permanently?"
    );
    if (confirm) {
      const _id = groupset._id;
      const response = await deleteGroupset({ _id });
      if (response) {
        await getGroupsets();
        showAlert("Success", "Groupset deleted successfully", "success");
      } else showAlert("Failed", "Groupset delete failed", "error");
    }
  };

  return (
    <>
      <div
        style={{
          padding: "20px",
          margin: "20px auto",
          maxWidth: "1200px",
          border: "1px solid black",
          borderRadius: "8px",
          backgroundColor: "#fff",
          boxShadow: "10px 32px 18px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            fontSize: "30px",
            fontWeight: "bold",
            color: "#0060ca",
            marginBottom: "20px",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          Groupsets
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
            flexWrap: "wrap",
          }}
        >
          <div style={{ fontSize: "16px", fontWeight: "bold" }}>
            {filteredGroupsets.length} groupset(s) found.
          </div>
          <Input
              placeholder="Search by name"
              value={searchText}
              onChange={handleSearch}
              prefix={<SearchOutlined />}
              style={{ width: "300px" }}
            />
          <div style={{ display: "flex", gap: "100px" }}>
            
            <Button
              type="primary"
              onClick={() => openModal("save", {})}
              icon={<PlusCircleOutlined />}
              style={{ fontSize: "16px" }}
            >
              Create
            </Button>
          </div>
        </div>

        <Spin spinning={loading} tip="Loading...">
          <div style={{ overflowX: "auto" }}>
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={{ pageSize: 10 }}
              style={{
                border: "1px solid #f0f0f0",
                borderRadius: "8px",
              }}
            />
          </div>
        </Spin>
      </div>

      {/* Modal */}
      {isOpenModal && (
        <ModifyGroupset
          open={isOpenModal}
          onClose={closeModal}
          flag={flag}
          allgroups={groups}
          params={params}
        />
      )}
    </>
  );
};

export default ListGroupsets;
