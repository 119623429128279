import React, { useEffect, useState ,useRef,useCallback} from "react";
import Loader from "../../components/Loader";
import { Input ,Button} from "antd";
import showAlert from "../../components/showAlert";
import { getAllContacts } from "../../services/service";
const PAGE_SIZE = 40; 

const ListContacts = () => {
 

  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setselectedContact] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const loaderRef = useRef(null);
  useEffect(() => {
    get_all_contacts();
  }, []);

  const get_all_contacts = async () => {
    setLoading(true);
    try {
      const data = await getAllContacts();
      const validContacts = data.filter(
        (contact) => contact.isWAContact && contact.id.endsWith("@c.us")
      );
      setContacts(validContacts);
      setFilteredContacts(validContacts.slice(0, PAGE_SIZE)); // Load the first page of contacts
    } catch (error) {
      showAlert("Error fetching contacts");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreContacts = useCallback(() => {
    if (currentPage * PAGE_SIZE < contacts.length) {
      setCurrentPage((prevPage) => prevPage + 1);
      setFilteredContacts((prevContacts) => [
        ...prevContacts,
        ...contacts.slice(
          prevContacts.length,
          (currentPage + 1) * PAGE_SIZE
        ),
      ]);
    }
  }, [contacts, currentPage]);
  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreContacts(); // Trigger load more when the bottom of the list is in view
        }
      },
      { threshold: 1.0 } // Trigger when the "Load More" button is fully visible
    )
  );
  useEffect(() => {
    if (loaderRef.current) {
      observer.current.observe(loaderRef.current); // Observe the "Load More" button
    }

    return () => {
      if (loaderRef.current) {
        observer.current.unobserve(loaderRef.current); // Clean up observer on unmount
      }
    };
  }, [loaderRef.current]);

  // const loadMoreContacts = useCallback(() => {
  //   const nextPageContacts = contacts.slice(0, (currentPage + 1) * PAGE_SIZE);
  //   setFilteredContacts(nextPageContacts);
  //   setCurrentPage((prevPage) => prevPage + 1);
  // }, [contacts, currentPage]);
  const contact_on_click = (contact) => {
    console.log("selected contact: ", contact);
    setselectedContact(contact);
  };

  const search_contacts_input_on_change = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Filter contacts based on the search query
    const filtered = contacts.filter((contact) => (contact?.name || contact?.pushname || contact?.verifiedName || "").toLowerCase().includes(query.toLowerCase()));
    setFilteredContacts(filtered);
  };

  return (
    <>
      <h1>ListContacts</h1>
      {loading && (
        <>
          <Loader />
        </>
      )}
      {!loading && (
        <div>
          <div style={{ display: "Flex", flexDirection: "row", justifyContent: "space-between", width: 600 }}>
            <div>{filteredContacts.length} contact(s) found</div>
            <div>
              <div>Search</div>
              <div>
                <Input placeholder="Search contacts by name" value={searchQuery} onChange={search_contacts_input_on_change} style={{ marginBottom: "20px", width: "100%" }} />
              </div>
            </div>
          </div>

          <div style={{ display: "Flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div style={{ width: 600, height: 400, overflow: "auto" }}>
              {filteredContacts.map((contact, i) => (
                <div
                  onClick={() => console.log(contact)}
                  key={i}
                  style={{
                    display: "Flex",
                    flexDirection: "column",
                    padding: 10,
                    margin: 10,
                    border: "1px solid gray",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ fontSize: 12, fontWeight: 500 }}>
                    {contact?.name} {contact?.pushname} {contact?.verifiedName}
                  </div>
                  <div style={{ fontSize: 10, color: "gray" }}>{contact.number}</div>
                </div>
              ))}

              {/* Lazy loading trigger */}
              {filteredContacts.length < contacts.length && (
                <div ref={loaderRef}>
                  <Button onClick={loadMoreContacts} block>
                    Load More
                  </Button>
                </div>
              )}
            </div>

         

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row", padding: 5, margin: 5, border: "1px solid #d1d1d1" }}>
                <div style={{ width: 200 }}>Name: </div>
                <div>{selectedContact?.name || "N/A"}</div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", padding: 5, margin: 5, border: "1px solid #d1d1d1" }}>
                <div style={{ width: 200 }}>Short Name: </div>
                <div>{selectedContact?.shortName || "N/A"}</div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", padding: 5, margin: 5, border: "1px solid #d1d1d1" }}>
                <div style={{ width: 200 }}>Push Name: </div>
                <div>{selectedContact?.pushname || "N/A"}</div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", padding: 5, margin: 5, border: "1px solid #d1d1d1" }}>
                <div style={{ width: 200 }}>Verified Name: </div>
                <div>{selectedContact?.verifiedName || "N/A"}</div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", padding: 5, margin: 5, border: "1px solid #d1d1d1" }}>
                <div style={{ width: 200 }}>Number: </div>
                <div>{selectedContact?.number || "N/A"}</div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", padding: 5, margin: 5, border: "1px solid #d1d1d1" }}>
                <div style={{ width: 200 }}>Is Me? </div>
                <div>{selectedContact?.isMe ? "✅" : "❌"}</div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", padding: 5, margin: 5, border: "1px solid #d1d1d1" }}>
                <div style={{ width: 200 }}>Is User? </div>
                <div>{selectedContact?.isUser ? "✅" : "❌"}</div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", padding: 5, margin: 5, border: "1px solid #d1d1d1" }}>
                <div style={{ width: 200 }}>Is Group? </div>
                <div>{selectedContact?.isGroup ? "✅" : "❌"}</div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", padding: 5, margin: 5, border: "1px solid #d1d1d1" }}>
                <div style={{ width: 200 }}>Is a WA Contact?</div>
                <div> {selectedContact?.isWAContact ? "✅" : "❌"}</div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", padding: 5, margin: 5, border: "1px solid #d1d1d1" }}>
                <div style={{ width: 200 }}>Is in My Contact? </div>
                <div>{selectedContact?.isMyContact ? "✅" : "❌"}</div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", padding: 5, margin: 5, border: "1px solid #d1d1d1" }}>
                <div style={{ width: 200 }}>Is Blocked?</div>
                <div> {selectedContact?.isBlocked ? "✅" : "❌"}</div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", padding: 5, margin: 5, border: "1px solid #d1d1d1" }}>
                <div style={{ width: 200 }}>Is Business? </div>
                <div>{selectedContact?.isBusiness ? "✅" : "❌"}</div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", padding: 5, margin: 5, border: "1px solid #d1d1d1" }}>
                <div style={{ width: 200 }}>Is Enterprise? </div>
                <div>{selectedContact?.isEnterprise ? "✅" : "❌"}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ListContacts;
