import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "antd";
import { createGroup } from "../../services/service";
import Loader from "../../components/Loader";
import showAlert from "../../components/showAlert";
import { getAllContacts } from "../../services/service";

const PAGE_SIZE = 20; // Number of contacts to load per page

const CreateGroup = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    get_all_contacts();
  }, []);

  const get_all_contacts = async () => {
    setLoading(true);
    await getAllContacts().then((contacts) => {
      const validContacts = contacts.filter((contact) => contact.isWAContact && contact.id.endsWith("@c.us"));
      setContacts(validContacts || []);
      setFilteredContacts(validContacts.slice(0, PAGE_SIZE)); // Load only the first page initially
      setLoading(false);
    });
  };

  const loadMoreContacts = useCallback(() => {
    const nextPageContacts = contacts.slice(0, (currentPage + 1) * PAGE_SIZE);
    setFilteredContacts(nextPageContacts);
    setCurrentPage((prevPage) => prevPage + 1);
  }, [contacts, currentPage]);

  const search_contacts_input_on_change = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Filter contacts based on the search query
    const filtered = contacts.filter((contact) =>
      (contact?.name || contact?.pushname || contact?.verifiedName || "").toLowerCase().includes(query.toLowerCase())
    );
    setFilteredContacts(filtered.slice(0, PAGE_SIZE)); // Reset to the first page of search results
    setCurrentPage(1);
  };

  const isContactSelected = (contactId) => selectedContacts.includes(contactId);

  const contact_row_on_click = (contactId) => {
    const isSelected = isContactSelected(contactId);
    setSelectedContacts((prevSelectedContacts) => {
      if (!isSelected) return [...prevSelectedContacts, contactId];
      else return prevSelectedContacts.filter((selectedId) => selectedId !== contactId);
    });
  };

  const handleSubmit = async (e) => {
    if (groupName.trim().length <= 0) showAlert("Alert", "Group name is required.", "info");
    else if (selectedContacts.length <= 0) showAlert("Alert", "At least one contact is required.", "info");
    else {
      setLoading(true);
      createGroup({ groupName, contacts: selectedContacts }).then((res) => {
        setLoading(false);
        res?.status === "success"
          ? showAlert("Success", "Group created successfully", "success")
          : showAlert("Error", "An error occurred while creating the group", "error");
        reset();
      });
    }
  };

  const reset = () => {
    setGroupName("");
    setSearchQuery("");
    setSelectedContacts([]);
  };

  return (
    <>
      <h1>CreateGroup</h1>
      {loading && <Loader />}
      {!loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "10vh",
            padding: 20,
            margin: 20,
            backgroundColor: "#f0f2f5",
          }}
        >
          <div>
            <div style={{ display: "flex", flexDirection: "column", padding: 10, margin: 10 }}>
              <div>Group Name</div>
              <Input value={groupName} onChange={(e) => setGroupName(e.target.value)} autoFocus />
            </div>

            <div style={{ display: "flex", flexDirection: "column", padding: 10, margin: 10 }}>
              <div>Search contact | {selectedContacts.length} contact(s) selected</div>
              <Input value={searchQuery} onChange={search_contacts_input_on_change} />
              <div style={{ width: 600, height: 250, overflow: "auto" }}>
                {filteredContacts.map((contact) => {
                  const isSelected = isContactSelected(contact.id);
                  return (
                    <div
                      key={contact.id}
                      onClick={() => contact_row_on_click(contact.id)}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: 10,
                        margin: 10,
                        backgroundColor: isSelected ? "lightgreen" : "white",
                        cursor: isSelected ? "not-allowed" : "pointer",
                        opacity: isSelected ? 0.6 : 1,
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ fontSize: 12, fontWeight: 500 }}>
                          {contact?.name} {contact?.pushname} {contact?.verifiedName}
                        </div>
                        <div style={{ fontSize: 10, color: "gray" }}>{contact.number}</div>
                      </div>
                    </div>
                  );
                })}
                {filteredContacts.length < contacts.length && (
                  <Button onClick={loadMoreContacts} block>
                    Load More
                  </Button>
                )}
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row", padding: 10, margin: 10 }}>
              <Button onClick={() => navigate("/groups")} block>
                Cancel
              </Button>
              <div style={{ width: 20 }} />
              <Button onClick={handleSubmit} type="primary" block>
                Submit
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateGroup;
