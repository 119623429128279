import React, { useEffect, useState } from "react";
import {
  getAllMessageTemplates,
  deleteMessageTemplate,
} from "../../services/service";
import { Button, Table, Input } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import showAlert from "../../components/showAlert";
import ModifyMessageTemplate from "./ModifyMessageTemplate";
import showConfirm from "../../components/showConfirm";

const ListMessageTemplates = () => {
  useEffect(() => {
    getMessageTemplates();
  }, []);

  const [messageTemplates, setMessageTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [searchText, setSearchText] = useState("");

  const getMessageTemplates = async () => {
    const data = await getAllMessageTemplates();
    setMessageTemplates(data || []); // Ensure data is an array
    setFilteredTemplates(data || []); // Initialize filtered data
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    const filtered = messageTemplates.filter((template) =>
      template.name.toLowerCase().includes(value)
    );
    setFilteredTemplates(filtered);
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Text",
      dataIndex: "text",
      key: "text",
    },
    {
      title: "Media URL",
      dataIndex: "mediaurl",
      key: "mediaurl",
      render: (url) => (url ? url : "N/A"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            type="primary"
            onClick={() => openModal("update", record)}
            icon={<EditOutlined />}
          />
          <Button
            type="primary"
            danger
            onClick={() => delete_button_on_click(record)}
            icon={<DeleteOutlined />}
          />
        </div>
      ),
    },
  ];

  const dataSource = filteredTemplates.map((template, index) => ({
    key: index,
    ...template,
  }));

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [flag, setFlag] = useState("save");
  const [params, setParams] = useState({});

  const openModal = (flag, params) => {
    setFlag(flag);
    if (flag === "save") {
      setParams({});
    } else {
      setParams({
        _id: params._id,
        id: params.id,
        name: params.name,
        type: params.type,
        mediaurl: params.mediaurl,
        text: params.text,
      });
    }
    setIsOpenModal(true);
  };

  const closeModal = async (result) => {
    if (result) await getMessageTemplates();
    setIsOpenModal(false);
  };

  const delete_button_on_click = async (messageTemplate) => {
    const confirm = await showConfirm(
      "Confirmation",
      "Are you sure you want to remove it permanently?"
    );
    if (confirm) {
      const _id = messageTemplate._id;
      const response = await deleteMessageTemplate({ _id });
      if (response) {
        await getMessageTemplates();
        showAlert(
          "Success",
          "Message template deleted successfully",
          "success"
        );
      } else {
        showAlert("Failed", "Message template delete failed", "error");
      }
    }
  };

  return (
    <>
      <div
        style={{
          padding: "20px",
          margin: "20px auto",
          maxWidth: "1200px",
          border: "1px solid black",
          borderRadius: "8px",
          backgroundColor: "#fff",
          boxShadow: "10px 32px 18px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            fontSize: "30px",
            fontWeight: "bold",
            color: "#0060ca",
            marginBottom: "20px",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          Message Templates
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div style={{ fontSize: "16px", fontWeight: "bold" }}>
            {filteredTemplates.length} message template(s) found.
          </div>
          <Button
            type="primary"
            onClick={() => openModal("save", {})}
            icon={<PlusCircleOutlined />}
            style={{ fontSize: "16px" }}
          >
            Create
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            // justifyContent: "flex-end",
            marginBottom: "10px",
          }}
        >
          <Input
            placeholder="Search by template name"
            value={searchText}
            onChange={handleSearch}
            prefix={<SearchOutlined />}
            style={{ width: "300px" }}
          />
        </div>

        <div style={{ overflowX: "auto" }}>
          <Table
            columns={columns.map((col) => ({
              ...col,
              ellipsis: true, // Enable ellipsis for long text
            }))}
            dataSource={dataSource}
            pagination={{ pageSize: 10 }}
            rowKey="key"
            style={{
              border: "1px solid #f0f0f0",
              borderRadius: "8px",
            }}
          />
        </div>
      </div>

      {/* Modal */}
      {isOpenModal && (
        <ModifyMessageTemplate
          open={isOpenModal}
          onClose={closeModal}
          flag={flag}
          params={params}
        />
      )}
    </>
  );
};

export default ListMessageTemplates;
