import axios from "axios";

// Define the baseURL as a string
const baseURL = "https://thinkzone.info/";

// Define the version object
export const Version = {
  version: "1.0",
};

// Create an Axios instance with the base URL
export default axios.create({
  baseURL: baseURL,
});
