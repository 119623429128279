import React,{useState} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TopNavMenu from "./components/TopNavMenu";

import Home from "./pages/home/index";
import Messages from "./pages/messages/index";
import Contacts from "./pages/contacts/index";

// Groups
import Groups from "./pages/groups/index";
// import ListGroups from "./pages/groups/ListGroups";
import CreateGroup from "./pages/groups/CreateGroup";

// Message Templates
import MessageTemplates from "./pages/message_templates";

// Groupsets
import Groupset from "./pages/groupsets";
import SheduleReport from "./pages/shedule/index";
import LoginPage from "./pages/LoginPage";
import Logout from "./pages/Logout";


const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
  };
  return (
    <Router>
    {localStorage.getItem("isLogedIn") === "true" ? (
      <>
        {/* <SimpleDrawer />
        <Routes>
          <Route path="/*" element={<AppRoute />} />
          <Route
            path="/logout"
            element={<Logout onLogout={handleLogout} />}
          />
        </Routes> */}
        <div>
        <TopNavMenu />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/groups" element={<Groups />} />
          <Route path="/creategroup" element={<CreateGroup />} />
          <Route path="/messagetemplate" element={<MessageTemplates />} />
          <Route path="/groupset" element={<Groupset />} />
          <Route path="/shedulereport" element={<SheduleReport />} />
          <Route
            path="/logout"
            element={<Logout onLogout={handleLogout} />}
          />
        </Routes>
      </div>
      </>
    ) : (
      <Routes>
        <Route path="/" element={<LoginPage onLogin={handleLogin} />} />
      </Routes>
    )}
  </Router>
    // <Router>
    //   <div>
    //     <TopNavMenu />

    //     <Routes>
    //       <Route path="/" element={<Home />} />
    //       <Route path="/messages" element={<Messages />} />
    //       <Route path="/contacts" element={<Contacts />} />
    //       <Route path="/groups" element={<Groups />} />
    //       <Route path="/creategroup" element={<CreateGroup />} />
    //       <Route path="/messagetemplate" element={<MessageTemplates />} />
    //       <Route path="/groupset" element={<Groupset />} />
    //       <Route path="/shedulereport" element={<SheduleReport />} />

    //     </Routes>
    //   </div>
    // </Router>
  );
};

export default App;
