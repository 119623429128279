import React, { useState, useEffect } from "react";
import { Modal, Card, Col, Row, Button, Input, Statistic } from "antd";
import {
  SwapOutlined,
  LoadingOutlined,
  GroupOutlined,
  UserOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";
import CountUp from "react-countup";
import { useNavigate } from "react-router-dom";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  syncWAData,
  getAllGroups,
  getAllGroupsets,
  getAllContacts,
} from "../../services/service";
import showAlert from "../../components/showAlert";

const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const [groupsets, setGroupsets] = useState([]);
  const [contact, setContacts] = useState([]);

  const getGroupsets = async () => {
    try {
      const data = await getAllGroupsets();
      setGroupsets(data || []);
    } catch (error) {
      console.error("Error fetching groupsets:", error);
    }
  };

  const getContacts = async () => {
    try {
      const data = await getAllContacts();
      const validContacts = (data || []).filter(
        (contact) => contact.isWAContact && contact.id.endsWith("@c.us")
      );
      setContacts(validContacts);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const data = [
    { name: "Jan", uv: 4000 },
    { name: "Feb", uv: 3000 },
    { name: "Mar", uv: 2000 },
    { name: "Apr", uv: 2780 },
    { name: "May", uv: 1890 },
    { name: "Jun", uv: 2390 },
  ];

  useEffect(() => {
    setLoading(true);
    getGroupsets();
    getContacts();
    getAllGroups()
      .then((grs) => {
        setAllGroups(grs || []);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching groups:", error);
        setLoading(false);
      });
  }, []);

  const syncData = async () => {
    setLoading(true);
    try {
      const data = await syncWAData();
      if (data.status === "success") {
        showAlert("Success", "Data sync successful.", "success");
      } else {
        showAlert("Failed", "Error in data sync.", "error");
      }
    } catch (error) {
      console.error("Server error: data not synced.", error);
      showAlert("Failed", "Server error: data not synced.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleSyncClick = () => {
    setShowConfirm(true);
  };

  const handleConfirm = () => {
    setShowConfirm(false);
    setShowPasswordModal(true);
  };

  const handlePasswordSubmit = () => {
    if (password === "TzAuto@2014") {
      setIsAuthenticated(true);
      setShowPasswordModal(false);
      syncData();
    } else {
      showAlert("Failed", "Incorrect password. Please try again.", "error");
    }
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
          padding: "2% 10%",
        }}
      >
        <h1
          style={{
            fontWeight: "bold",
            fontSize: "30px",
            color: "#0060ca",
            textTransform: "uppercase",
            margin: 0,
          }}
        >
          Dashboard
        </h1>
        {!loading ? (
          <Button
            icon={<SwapOutlined />}
            type="primary"
            onClick={handleSyncClick}
            style={{
              marginLeft: "20px",
            }}
          >
            Sync Data
          </Button>
        ) : (
          <span>
            <LoadingOutlined />
            Syncing, please wait...
          </span>
        )}
      </div>

      <Row
        gutter={16}
        style={{
          marginBottom: 20,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Col span={7}>
          <Card
            bordered={true}
            style={{
              textAlign: "center",
              border: "1px solid black",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
            }}
            bodyStyle={{
              cursor: "pointer",
              transition: "transform 0.3s ease-in-out",
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.transform = "scale(1.05)")
            }
            onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
            onClick={() => {
              navigate("/groups");
            }}
          >
            <Statistic
              title={
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#457b9d",
                    fontFamily: "'Roboto', sans-serif",
                  }}
                >
                  Total Groups
                </span>
              }
              valueRender={() => (
                <CountUp
                  start={0}
                  end={allGroups.length}
                  duration={2}
                  separator=","
                />
              )}
              prefix={
                <GroupOutlined style={{ fontSize: "24px", color: "#1890ff" }} />
              }
              suffix={
                <span role="img" aria-label="groups">
                  👥
                </span>
              }
            />
          </Card>
        </Col>

        <Col span={7}>
          <Card
            bordered={true}
            style={{
              textAlign: "center",
              border: "1px solid black",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
            }}
            bodyStyle={{
              cursor: "pointer",
              transition: "transform 0.3s ease-in-out",
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.transform = "scale(1.05)")
            }
            onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
            onClick={() => {
              navigate("/contacts");
            }}
          >
            <Statistic
              title={
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#457b9d",
                    fontFamily: "'Roboto', sans-serif",
                  }}
                >
                  Total Contacts
                </span>
              }
              valueRender={() => (
                <CountUp
                  start={0}
                  end={contact.length}
                  duration={2}
                  separator=","
                />
              )}
              prefix={
                <UserOutlined style={{ fontSize: "24px", color: "#1890ff" }} />
              }
              suffix={
                <span role="img" aria-label="contacts">
                  📞
                </span>
              }
            />
          </Card>
        </Col>

        <Col span={7}>
          <Card
            bordered={true}
            style={{
              textAlign: "center",
              border: "1px solid black",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
            }}
            bodyStyle={{
              cursor: "pointer",
              transition: "transform 0.3s ease-in-out",
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.transform = "scale(1.05)")
            }
            onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
            onClick={() => {
              navigate("/groupset");
            }}
          >
            <Statistic
              title={
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#457b9d",
                    fontFamily: "'Roboto', sans-serif",
                  }}
                >
                  Total Groupsets
                </span>
              }
              valueRender={() => (
                <CountUp
                  start={0}
                  end={groupsets.length}
                  duration={2}
                  separator=","
                />
              )}
              prefix={
                <AppstoreAddOutlined
                  style={{ fontSize: "24px", color: "#1890ff" }}
                />
              }
              suffix={
                <span role="img" aria-label="groupsets">
                  📊
                </span>
              }
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={24}>
          <Card title="Data Trends" bordered={true}>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="uv" stroke="#1890ff" />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>

      <Modal
        title="Confirm Sync"
        visible={showConfirm}
        onOk={handleConfirm}
        onCancel={() => setShowConfirm(false)}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to sync data?</p>
      </Modal>

      <Modal
        title="Authenticate"
        visible={showPasswordModal}
        onCancel={() => setShowPasswordModal(false)}
        footer={null}
        destroyOnClose
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Input
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ marginBottom: 20 }}
          />
          <Button type="primary" onClick={handlePasswordSubmit}>
            Submit
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Home;
