import React, { useState } from "react";
import { Button, Input, Modal, Checkbox } from "antd";
import showAlert from "../../components/showAlert";
import { createGroupset, updateGroupset } from "../../services/service";

const { Search } = Input;

const ModifyGroupset = ({ open, onClose, flag, allgroups, params = {} }) => {
  const [_id, set_id] = useState(params._id || "");
  const [name, setName] = useState(params.name || "");
  const [groups, setGroups] = useState(params.groups || []);
  const [filteredGroups, setFilteredGroups] = useState(allgroups || []);
  const [searchString, setSearchString] = useState("");

  const isAllSelected = groups.length === filteredGroups.length;

  const save_button_on_click = async () => {
    if (!name) showAlert("Alert", "Please enter name", "error");
    else if (groups.length <= 0) showAlert("Alert", "Please select at least one group", "error");
    else {
      flag === "save" ? save() : update();
    }
  };

  const save = async () => {
    const groupsetData = {
      id: new Date().getTime(),
      name,
      groups,
    };
    const response = await createGroupset(groupsetData);
    if (response) {
      showAlert("Success", "Groupset created successfully", "success");
      onClose(groupsetData);
    } else showAlert("Failed", "Groupset creation failed", "error");
  };

  const update = async () => {
    const groupsetData = {
      _id,
      name,
      groups,
    };
    const response = await updateGroupset(groupsetData);
    if (response) {
      showAlert("Success", "Groupset updated successfully", "success");
      onClose(groupsetData);
    } else showAlert("Failed", "Groupset updation failed", "error");
  };

  const reset = () => {
    setName("");
    setGroups([]);
  };

  const onSearch = (value) => {
    setSearchString(value);
    const filtered = allgroups.filter((group) => group.name.toLowerCase().includes(value.toLowerCase()));
    setFilteredGroups(filtered);
  };

  const toggleSelectAll = () => {
    if (isAllSelected) {
      setGroups([]);
    } else {
      const allSelectedGroups = filteredGroups.map((group) => ({
        groupid: group.id,
        groupname: group.name,
      }));
      setGroups(allSelectedGroups);
    }
  };

  const onGroupCheckboxChange = (selectedValues) => {
    const updatedGroups = selectedValues.map((id) => {
      const group = filteredGroups.find((group) => group.id === id);
      return { groupid: group.id, groupname: group.name };
    });
    setGroups(updatedGroups);
  };

  return (
    <Modal
      title={flag === "save" ? "Create Groupset" : "Update Groupset"}
      open={open}
      onCancel={() => onClose(null)}
      footer={[
        <Button key="reset" onClick={reset}>
          Reset
        </Button>,
        <Button key="save" type="primary" onClick={save_button_on_click}>
          Save
        </Button>,
      ]}
    >
      <div>
        <div style={{ display: "flex", flexDirection: "row", padding: 10, margin: 10, fontSize: 12 }}>
          <div style={{ width: 50 }}>Title</div>
          <div>
            <Input value={name} onChange={(e) => setName(e.target.value)} style={{ width: 250 }} />
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row", padding: 10, margin: 10, fontSize: 12 }}>
          <div style={{ width: 50 }}>Search</div>
          <div>
            <Input value={searchString} onChange={(e) => onSearch(e.target.value)} style={{ width: 250 }} />
          </div>
        </div>

        <div style={{ height: 200, overflow: "auto" }}>
          <Checkbox
            indeterminate={groups.length > 0 && !isAllSelected}
            checked={isAllSelected}
            onChange={toggleSelectAll}
            style={{ marginBottom: 10 }}
          >
            Select All
          </Checkbox>
          <Checkbox.Group
            value={groups.map((g) => g.groupid)}
            onChange={onGroupCheckboxChange}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {filteredGroups.map((group) => (
              <Checkbox key={group.id} value={group.id}>
                {group.name}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </div>
      </div>
    </Modal>
  );
};

export default ModifyGroupset;
