import React, { useState } from "react";
import { Button, Input, Select, Modal } from "antd";
import EmojiPicker from "emoji-picker-react";
import showAlert from "../../components/showAlert";
import {
  createMessageTemplate,
  updateMessageTemplate,
} from "../../services/service";

const { TextArea } = Input;
const { Option } = Select;

const ModifyMessageTemplate = ({ open, onClose, flag, params = {} }) => {
  const [_id, set_id] = useState(params._id || "");
  const [name, setName] = useState(params.name || "");
  const [type, setType] = useState(params.type || "Select Type");
  console.log("type============>", type);

  const [mediaurl, setMediaurl] = useState(params.mediaurl || "");
  const [showMediaurl, setShowMediaurl] = useState(params.type !== "text");
  const [text, setText] = useState(params.text || "");
  const [showPicker, setShowPicker] = useState(false);

  const MAX_CHARACTERS_FOR_BODY = 1000;

  const type_select_on_change = (value) => {
    setType(value);
    setShowMediaurl(value !== "text");
  };

  const save_button_on_click = () => {
    if (!name) {
      showAlert("Alert", "Please enter name", "error");
    } else if (type !== "text" && !mediaurl) {
      showAlert("Alert", "Please enter media URL", "error");
    } else if (!text) {
      showAlert("Alert", "Please enter message text", "error");
    } else {
      flag === "save" ? save() : update();
    }
  };

  const applyFormatting = (format) => {
    const textarea = document.querySelector("textarea");
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;

    if (startPos === endPos) return;

    const selectedText = text.substring(startPos, endPos).trim();
    const formatSymbols = {
      bold: "*",
      italic: "_",
      strike: "~",
      monospace: "~~~",
    };

    const formattedText =
      text.substring(0, startPos) +
      formatSymbols[format] +
      selectedText +
      formatSymbols[format] +
      " " +
      text.substring(endPos);

    if (formattedText.length <= MAX_CHARACTERS_FOR_BODY) {
      setText(formattedText);
    }
  };

  const save = async () => {
    const templateData = {
      id: new Date().getTime(),
      name,
      type: type === "text" ? "text" : "media",
      text,
      mediaurl,
    };

    const response = await createMessageTemplate(templateData);
    if (response) {
      showAlert("Success", "Message template created successfully", "success");
      onClose(templateData);
    } else {
      showAlert("Failed", "Message template creation failed", "error");
    }
  };

  const update = async () => {
    const templateData = {
      _id,
      name,
      type: type === "text" ? "text" : "media",
      text,
      mediaurl,
    };

    const response = await updateMessageTemplate(templateData);
    if (response) {
      showAlert("Success", "Message template updated successfully", "success");
      onClose(templateData);
    } else {
      showAlert("Failed", "Message template update failed", "error");
    }
  };

  const reset = () => {
    setName("");
    setType("text");
    setMediaurl("");
    setShowMediaurl(false);
    setText("");
  };

  const handleEmojiClick = (emojiData) => {
    setText((prev) => prev + emojiData.emoji);
    setShowPicker(false);
  };

  return (
    <Modal
      title={flag === "save" ? "Create Template" : "Update Template"}
      open={open}
      onCancel={() => onClose(null)}
      footer={[
        <Button key="reset" onClick={reset}>
          Reset
        </Button>,
        <Button key="save" type="primary" onClick={save_button_on_click}>
          Save
        </Button>,
      ]}
    >
      <div
        style={{
          padding: "15px",
          border: "1px solid #dcdfe6",
          borderRadius: "8px",
          backgroundColor: "#f9f9f9",
          marginBottom: "20px",
        }}
      >
        <strong style={{ color: "red" }}>Instruction:</strong> Please check the
        following rules: Accepted file types include Audio <b>(.mp3)</b>, Video{" "}
        <b>(.mp4)</b>, Document <b>(.docx, .txt, .pdf)</b>, and Image{" "}
        <b>(.png, .jpg, .jpeg)</b>, all with a size limit within <b>15 MB</b>.
      </div>
      <div>
        {/* Name Input */}

        <div style={{ display: "flex", padding: 10 }}>
          <div style={{ width: 50 }}>Title</div>

          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ width: 250 }}
          />
        </div>

        {/* Type Selector */}
        <div style={{ display: "flex", padding: 10 }}>
          <div style={{ width: 50 }}>Type</div>
          <Select
            value={type}
            onChange={type_select_on_change}
            placeholder="Select type"
            style={{ width: 250 }}
          >
            <Option value="text">Text</Option>
            <Option value="media">Media(Audio/Video/Image/Document)</Option>
          </Select>
        </div>
 
        {/* Media URL Input */}
        {showMediaurl && (
          <div style={{ display: "flex", padding: 10 }}>
            <div style={{ width: 50 }}>URL</div>
            <Input
              value={mediaurl}
              onChange={(e) => setMediaurl(e.target.value)}
              style={{ width: 250 }}
            />
          </div>
        )}

        {/* Text Input */}
        <div style={{ display: "flex", flexDirection: "column", padding: 10 }}>
          <div>Text</div>
          <TextArea
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Enter your message here..."
            style={{ height: 100, width: "100%", resize: "none" }}
            maxLength={MAX_CHARACTERS_FOR_BODY}
          />
          <div>Characters: {`${text.length}/${MAX_CHARACTERS_FOR_BODY}`}</div>
          <div style={{ marginTop: 10, display: "flex", gap: 10 }}>
            <span
              onClick={() => setShowPicker(!showPicker)}
              style={{ cursor: "pointer" }}
            >
              ☺️
            </span>
            {showPicker && (
              <div style={{ position: "absolute", zIndex: 10 }}>
                <EmojiPicker onEmojiClick={handleEmojiClick} />
              </div>
            )}
            <Button onClick={() => applyFormatting("bold")}>B</Button>
            <Button onClick={() => applyFormatting("italic")}>I</Button>
            <Button onClick={() => applyFormatting("strike")}>S</Button>
            {/* <Button onClick={() => applyFormatting("monospace")}>&lt;/&gt;</Button> */}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModifyMessageTemplate;
