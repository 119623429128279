import React from "react";
import ListGroupsets from "./ListGroupsets";

const Groupset = () => {
  return (
    <>
      <div>
        <ListGroupsets />
      </div>
    </>
  );
};
export default Groupset;
