import React, { useEffect, useState } from "react";
import { Table, Pagination, Spin, Typography, Space } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { getScheduledJobs } from "../../services/service"; // Update with the actual path to your API file

const { Title } = Typography;

function SheduleReport() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const fetchScheduledJobs = async (page = 1, pageSize = 10) => {
    setLoading(true);
    const response = await getScheduledJobs();
    if (response) {
      setData(response.slice((page - 1) * pageSize, page * pageSize)); // Paginate data manually
      setPagination((prev) => ({
        ...prev,
        total: response.length,
        current: page,
        pageSize,
      }));
    }
    setLoading(false);
  };

  const handleTableChange = (pagination) => {
    fetchScheduledJobs(pagination.current, pagination.pageSize);
  };

  useEffect(() => {
    fetchScheduledJobs();
  }, []);

  const columns = [
    {
      title: "Sl No",
      dataIndex: "slno",
      key: "slno",
      render: (_text, _record, index) => index + 1, // Generate serial number dynamically
      onHeaderCell: () => ({
        style: {
          backgroundColor: "rgb(22,119,255)",
          fontSize: "15px",
          textAlign: "center",
          color: "white",
        }, // Red background for heading
      }),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => (date ? new Date(date).toLocaleDateString() : "N/A"), // Format to show only the date
      onHeaderCell: () => ({
        style: {
          backgroundColor: "rgb(22,119,255)",
          fontSize: "15px",
          textAlign: "center",
          color: "white",
        }, // Red background for heading
      }),
    },
    {
      title: "Time",
      dataIndex: "date",
      key: "time",
      render: (date) => (date ? new Date(date).toLocaleTimeString() : "N/A"), // Format to show only the time
      onHeaderCell: () => ({
        style: {
          backgroundColor: "rgb(22,119,255)",
          fontSize: "15px",
          textAlign: "center",
          color: "white",
        }, // Red background for heading
      }),
    },
    {
      title: "Repeat",
      dataIndex: "repeat",
      key: "repeat",
      render: (repeat) => (repeat ? "True" : "False"), // Show "True" or "False"
      onHeaderCell: () => ({
        style: {
          backgroundColor: "rgb(22,119,255)",
          fontSize: "15px",
          textAlign: "center",
          color: "white",
        }, // Red background for heading
      }),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => status || "N/A", // Show "N/A" if status is undefined or null
      onHeaderCell: () => ({
        style: {
          backgroundColor: "rgb(22,119,255)",
          fontSize: "15px",
          textAlign: "center",
          color: "white",
        }, // Red background for heading
      }),
    },
    {
      title: "Message",
      dataIndex: "messageText",
      key: "messageText",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "rgb(22,119,255)",
          fontSize: "15px",
          textAlign: "center",
          color: "white",
          width: "250px",
        },
      }),
    },
    {
      title: "Group Set Name",
      dataIndex: "groupsetName",
      key: "groupsetName",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "rgb(22,119,255)",
          fontSize: "15px",
          textAlign: "center",
          color: "white",
          width: "250px",
        },
      }),
    },
    {
      title: "Groups",
      dataIndex: "groups",
      key: "groups",
      render: (groupIds) =>
        groupIds && groupIds.length > 0 ? groupIds.join(", ") : "N/A",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "rgb(22,119,255)",
          fontSize: "15px",
          textAlign: "center",
          color: "white",
          width: "250px",
        },
      }),
    },
  ];

  return (
    <div
      style={{
        padding: "20px",
        margin: "20px auto",
        maxWidth: "1500px",
        border: "1px solid rgb(10, 10, 10)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            marginBottom: "10px",
            fontWeight: "bold",
            fontSize: "30px",
            color: "#0060ca",
            textTransform: "uppercase",
          }}
        >
          Scheduled Jobs Report
        </h2>
        <div
          style={{
            border: "1px solid rgb(0, 0, 0)",
            borderRadius: "8px",
            backgroundColor: "#fff",
            width: "12%",
          }}
        >
          <h2
            style={{
              textAlign: "center",
              marginBottom: "20px",
              fontWeight: "bold",
              fontSize: "20px",
              color: "#0060ca",
              textTransform: "uppercase",
            }}
          >
            Refresh
            <SyncOutlined
              spin={loading}
              onClick={() => fetchScheduledJobs()}
              style={{
                fontSize: "24px",
                cursor: "pointer",
                color: "#1890ff",
              }}
              title="Refresh"
            />
          </h2>
        </div>
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <Spin tip="Loading Scheduled Jobs..." size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.id}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            onChange: (page, pageSize) =>
              handleTableChange({ current: page, pageSize }),
          }}
          bordered
          style={{
            borderRadius: "8px",
            overflow: "hidden",
          }}
          scroll={{
            x: "max-content", // Enable horizontal scrolling for long content
          }}
        />
      )}
    </div>
  );
}

export default SheduleReport;
