import React, { useState, useEffect } from "react";
import {
  Button,
  Select,
  Spin,
  DatePicker,
  TimePicker,
  Radio,
  Switch,
} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  getAllMessageTemplates,
  getAllGroupsets,
  sendMessageToGroups,
} from "../../services/service";
import Loader from "../../components/Loader";
import showAlert from "../../components/showAlert";
import dayjs from "dayjs";
import { scheduleJob } from "../../services/service";
import { type } from "@testing-library/user-event/dist/type";

const { Option } = Select;

const SendMessage = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);

    getAllMessageTemplates()
      .then((data) => {
        setAllTemplates(data ? data : []);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching message template:", error);
        setLoading(false);
      });

    getAllGroupsets()
      .then((data) => {
        setAllGroupsets(data ? data : []);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching groupsets:", error);
        setLoading(false);
      });
  }, []);

  const [allGroupsets, setAllGroupsets] = useState([]);
  console.log("====================================allGroupsets", allGroupsets);
  console.log();
  console.log("====================================");
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const groupset_select_on_change = (id) => {
    let value = allGroupsets.find((groupset) => groupset.id === id);
    setSelectedGroupset(value);
  };

  const [allTemplates, setAllTemplates] = useState([]);
  const [selectedGroupset, setSelectedGroupset] = useState({});
  const template_select_on_change = (id) => {
    let value = allTemplates.find((groupset) => groupset.id === id);
    setSelectedTemplate(value);
  };

  const reset = () => {
    // window.location.reload();
    setSelectedTemplate({});
    setSelectedGroupset({});
    setIsScheduled(false);
    setScheduledDate(null); // Clear the date
    setScheduledTime(null); // Clear the time
    setIsRepeat(false);
  };

  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [scheduledTime, setScheduledTime] = useState(null);
  const [isRepeat, setIsRepeat] = useState(false);

  const sendtype_radio_on_change = (value) => {
    if (value) {
      setIsScheduled(true);
    } else {
      setIsScheduled(false);
      setScheduledDate(null);
      setScheduledTime(null);
      setIsRepeat(false);
    }
  };

  console.log(
    "====================================selectedTemplate",
    selectedTemplate.type
  );

  const send_message_button_on_click = () => {
    if (Object.keys(selectedTemplate).length <= 0)
      showAlert("Alert", "Select message template.", "error");
    else if (Object.keys(selectedGroupset).length <= 0)
      showAlert("Alert", "Select groupset.", "error");
    else {
      const type = selectedTemplate.type;
      const mediaurl = selectedTemplate.mediaurl;
      const text = selectedTemplate.text;
      const groupIds = selectedGroupset?.groups.map((group) => group.groupid);
      setLoading(true);
      sendMessageToGroups({ groupIds, message: { type, mediaurl, text } }).then(
        (res) => {
          console.log("res:", res);
          setLoading(false);
          reset();
          showAlert("Success", "Message sent successfully", "success");
        }
      );
    }
  };
  const resetState = () => {
    setSelectedTemplate({});
    setSelectedGroupset({});
    setIsScheduled(false);
    setScheduledDate(null);
    setScheduledTime(null);
    setIsRepeat(false);
    window.location.reload();
  };
  const schedule_button_on_click = async () => {
    if (Object.keys(selectedTemplate).length <= 0)
      showAlert("Alert", "Select message template.", "error");
    else if (Object.keys(selectedGroupset).length <= 0)
      showAlert("Alert", "Select groupset.", "error");
    else if (!scheduledDate) showAlert("Alert", "Select date.", "error");
    else if (!scheduledTime) showAlert("Alert", "Select time.", "error");
    else {
      const messageType = selectedTemplate.type;
      const messageMediaUrl = selectedTemplate.mediaurl;
      const messageText = selectedTemplate.text;
      const groupIds = selectedGroupset?.groups.map((group) => group.groupid);
      const scheduleDateTime = dayjs(scheduledDate)
        .hour(scheduledTime.hour())
        .minute(scheduledTime.minute());
      const localISOTime = scheduleDateTime.format();
      console.log("Scheduled: ", isScheduled, "    Repeat: ", isRepeat);
      //const obj = { groupIds, message, dateTime: localISOTime, repeat: isRepeat };
      const obj = {
        id: new Date().getTime(),
        date: localISOTime,
        repeat: isRepeat,
        templateId: selectedTemplate.id,
        messageType,
        messageMediaUrl,
        messageText,
        groupsetId: selectedGroupset.id,
        groupIds,
      };

      try {
        const response = await scheduleJob(obj);
        console.log("====================================obj", obj);
        console.log(response, "response---------------->");
        console.log(
          "====================================response",
          response.data
        );

        showAlert("Success", response.message, "success");
        resetState();
      } catch (error) {
        showAlert("Error", "Error scheduling job: " + error.message, "error");
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <div
        style={{
          border: "1px solid black",
          borderRadius: "8px",
          backgroundColor: "#fff",
          boxShadow: "10px 32px 18px rgba(0, 0, 0, 0.1)",
          width: "50%",
          margin: "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            marginBottom: "20px",
            fontWeight: "bold",
            fontSize: "30px",
            color: "#0060ca",
            textTransform: "uppercase",
          }}
        >
          Send Message
        </h1>

        {loading && <Spin size="large" />}
        {!loading && (
          <div style={{ width: "100%", maxWidth: "600px" }}>
            {/* Template Selection */}
            <div style={{ marginBottom: "20px" }}>
              <h3>Select Template</h3>
              <Select
                onChange={template_select_on_change}
                value={selectedTemplate?.id}
                showSearch
                style={{ width: "100%" }}
                placeholder="Select message template"
                optionFilterProp="label"
              >
                {loading ? (
                  <Option disabled>
                    <Spin size="small" /> Loading...
                  </Option>
                ) : (
                  allTemplates.map((template) => (
                    <Option
                      key={template.id}
                      value={template.id}
                      label={template.name}
                    >
                      {template.name}
                    </Option>
                  ))
                )}
              </Select>
            </div>

            {/* Groupset Selection */}
            <div style={{ marginBottom: "20px" }}>
              <h3>Select Groupset</h3>
              <Select
                onChange={groupset_select_on_change}
                value={selectedGroupset?.id}
                showSearch
                style={{ width: "100%" }}
                placeholder="Select groups"
                optionFilterProp="label"
              >
                {loading ? (
                  <Option disabled>
                    <Spin size="small" /> Loading...
                  </Option>
                ) : (
                  allGroupsets.map((groupset) => (
                    <Option
                      key={groupset.id}
                      value={groupset.id}
                      label={groupset.name}
                    >
                      {groupset.name}
                    </Option>
                  ))
                )}
              </Select>
            </div>

            {/* Message Type Selection */}
            <div style={{ marginBottom: "20px" }}>
              <h3>Message Type</h3>
              <Radio.Group
                onChange={(e) =>
                  sendtype_radio_on_change(e.target.value === "schedule")
                }
                options={[
                  { label: "One Time", value: "onetime" },
                  { label: "Schedule", value: "schedule" },
                ]}
                defaultValue="onetime"
                optionType="button"
                buttonStyle="solid"
                style={{ display: "flex", justifyContent: "center" }}
              />
            </div>

            {/* Schedule Options */}
            {isScheduled && (
              <div style={{ marginBottom: "20px" }}>
                <div style={{ display: "flex", gap: "20px" }}>
                  <div>
                    <h4>Date</h4>
                    <DatePicker
                      value={scheduledDate}
                      onChange={setScheduledDate}
                      style={{ width: "100%" }}
                      disabledDate={(current) =>
                        current && current < dayjs().startOf("day")
                      }
                    />
                  </div>
                  <div>
                    <h4>Time</h4>
                    <TimePicker
                      value={scheduledTime}
                      onChange={setScheduledTime}
                      format="HH:mm"
                      style={{ width: "100%" }}
                      disabledTime={() => {
                        if (
                          scheduledDate &&
                          dayjs(scheduledDate).isSame(dayjs(), "day")
                        ) {
                          const currentHour = dayjs().hour();
                          const currentMinute = dayjs().minute();
                          return {
                            disabledHours: () =>
                              Array.from({ length: 24 }, (_, i) => i).filter(
                                (hour) => hour < currentHour
                              ),
                            disabledMinutes: (selectedHour) =>
                              selectedHour === currentHour
                                ? Array.from(
                                    { length: 60 },
                                    (_, i) => i
                                  ).filter((minute) => minute <= currentMinute)
                                : [],
                          };
                        }
                        return {};
                      }}
                    />
                  </div>
                </div>
                {/* <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <h4>Repeat</h4>
                  <Switch
                    onChange={setIsRepeat}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </div> */}
              </div>
            )}

            {/* Action Buttons */}
            <div
              style={{ display: "flex", justifyContent: "center", gap: "20px" }}
            >
              {/* <Button onClick={reset}>Cancel</Button> */}
              {isScheduled ? (
                <Button type="primary" onClick={schedule_button_on_click}>
                  Schedule
                </Button>
              ) : (
                <Button type="primary" onClick={send_message_button_on_click}>
                  Send Message
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SendMessage;
