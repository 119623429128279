import React from "react";
import ListMessageTemplates from "./ListMessageTemplates";

const MessageTemplates = () => {
  return (
    <>
      <div>
        <ListMessageTemplates />
      </div>
    </>
  );
};

export default MessageTemplates;
