import React, { useState } from "react";
import {
  Button,
  Layout,
  Form,
  Input,
  Typography,
  Alert,
  Spin,
  Modal,
} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import bgpic from "../assets/login1.png";
import bgpic1 from "../assets/backg1.jpg";
import styled from "styled-components";
import logo from "../assets/logo.png"
const { Content, Header } = Layout;
const { Title, Text } = Typography;

const LoginPage = ({ role, onLogin }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const [form] = Form.useForm();

  const staticEmail = "admin@tz.in";
  const staticPassword = "tzcda@2014";

  const handleSubmit = ({ email, password }) => {
    setMessage("");
    if (email === staticEmail && password === staticPassword) {
      setLoading(true);
      setTimeout(() => {
        onLogin();
        localStorage.setItem("isLogedIn", "true");
        navigate("/"); // Navigate to the Dashboard
      }, 1000);
    } else {
      setMessage("Invalid Email or Password");
      setShowModal(true);
    }
  };

  return (
    <StyledLayout>
      <Header style={{ backgroundColor: "#5e60ce", padding: 0 }}>
  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
    <img src={logo} style={{ width: "50px", marginRight: 10 }} />
    <Title level={3} style={{ color: "#fff", margin: 0 }}>
      ThinkZone WA Group Automation
    </Title>
  </div>
</Header>



      <Content style={{ display: "flex", height: "calc(100vh - 64px)" }}>
        {/* Left Section */}
        <StyledLeftSection />

        {/* Right Section */}
        <StyledRightSection>
          <StyledForm
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
          >
            <Title level={4}>{role} Login</Title>
            <Text>Log in with your email and password</Text>
            {message && <Alert message={message} type="error" showIcon closable />}

            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                { required: true, message: "Please input your email!" },
                { type: "email", message: "Invalid email format!" },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                placeholder="Password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={loading}
                style={{
                  background: "linear-gradient(45deg, #f72585, #7209b7)",
                  border: "none",
                }}
              >
                Login
              </Button>
            </Form.Item>
          </StyledForm>
        </StyledRightSection>

        {/* Modal */}
        <Modal
          title="Error"
          visible={showModal}
          onCancel={() => setShowModal(false)}
          footer={null}
        >
          <Text>{message}</Text>
        </Modal>
      </Content>
    </StyledLayout>
  );
};

const StyledLayout = styled(Layout)`
 width:100%;
   height: 100vh;
  background: url(${bgpic1}) no-repeat center center fixed opacity:0.5 ;
  background-size: cover;
  position: relative;
  
  bac
`;

const StyledLeftSection = styled.div`
  flex: 1;
  background-image: url(${bgpic});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

const StyledRightSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: #f9f9f9;
`;

const StyledForm = styled(Form)`
  width: 100%;
  max-width: 400px;
  background: #fff;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
`;

export default LoginPage;
