import React from "react";
import ListGroups from "./ListGroups";


const Groups = () => {
  return (
    <>
      <div>
     
        <ListGroups/>
      </div>
    </>
  );
};

export default Groups;
