import React from "react";

import SheduleReport from "./SheduleReport";


const SheduleReports = () => {
  return (
    <>
      <div>
       
        <SheduleReport/>
      </div>
    </>
  );
};

export default SheduleReports;
