import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu } from "antd";
import {
  HomeOutlined,
  MailOutlined,
  AppstoreOutlined,
  AuditOutlined,
  ContainerOutlined,
  BookOutlined,
  LogoutOutlined
} from "@ant-design/icons";
import logo from "../assets/tzicon.png";

const TopNavMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState(location.pathname);

  const handleClick = (e) => {
    setCurrent(e.key);
    navigate(e.key);
  };

  const containerStyles = {
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgb(0,21,41)",
    padding: "0 20px",
    borderBottom: "2px solid #4caf50",
  };

  const logoTitleContainer = {
    display: "flex",
    alignItems: "center",
    marginRight: "30px",
  };

  const logoStyles = {
    height: "40px",
    marginRight: "10px",
  };

  const titleStyles = {
    color: "#ffffff",
    fontSize: "20px",
    fontWeight: "bold",
    margin: 0,
  };

  const menuStyles = {
    flex: 1,
    justifyContent: "flex-end",
    height: "70px",
    lineHeight: "70px",
    background: "transparent",
  };

  const responsiveStyles = `
    @media screen and (max-width: 768px) {
      .ant-menu-horizontal {
        flex-direction: column;
        align-items: center;
      }
      .ant-menu-item {
        margin: 10px 0;
      }
      .logo-title-container {
        justify-content: center;
        margin-bottom: 10px;
      }
    }
  `;

  const itemStyles = {
    fontSize: "16px",
    fontWeight: "500",
    color: "#ffffff",
    transition: "all 0.3s ease",
    margin: "0 15px",
    position: "relative",
  };

  const hoverAnimationStyles = `
    .ant-menu-item:hover {
      color: #4caf50 !important;
    }
    .ant-menu-item:hover::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 2px;
      background-color: #4caf50;
      transition: all 0.3s ease;
      transform: translateX(-50%);
    }
    .ant-menu-item:hover::after {
      width: 80%;
    }
    .ant-menu-item-active, .ant-menu-item-selected {
      color: #4caf50 !important;
    }
    .ant-menu-item-active::after,
    .ant-menu-item-selected::after {
      width: 100%;
      background-color: #4caf50;
    }
  `;

  const items = [
    {
      label: (
        <span style={itemStyles}>
          <HomeOutlined /> Home
        </span>
      ),
      key: "/",
    },
    {
      label: (
        <span style={itemStyles}>
          <MailOutlined /> Messages
        </span>
      ),
      key: "/messages",
    },
    {
      label: (
        <span style={itemStyles}>
          <ContainerOutlined /> Groupsets
        </span>
      ),
      key: "/groupset",
    },
    {
      label: (
        <span style={itemStyles}>
          <AppstoreOutlined /> Templates
        </span>
      ),
      key: "/messagetemplate",
    },
    {
      label: (
        <span style={itemStyles}>
          <AuditOutlined /> Groups
        </span>
      ),
      key: "/groups",
    },
    {
      label: (
        <span style={itemStyles}>
          <BookOutlined /> Schedule Report
        </span>
      ),
      key: "/shedulereport",
    },
    {
      label: (
        <span style={itemStyles}>
        <LogoutOutlined /> LogOut
        </span>
      ),
      key: "/logout",
    },
  ];

  return (
    <div style={containerStyles}>
      {/* Inject responsive and hover styles */}
      <style>{responsiveStyles}</style>
      <style>{hoverAnimationStyles}</style>
      <div className="logo-title-container" style={logoTitleContainer}>
        <img src={logo} alt="Logo" style={logoStyles} />
        <h1 style={titleStyles}>ThinkZone Group Automation</h1>
      </div>
      <Menu
        items={items}
        onClick={handleClick}
        mode="horizontal"
        theme="dark"
        selectable
        selectedKeys={[current]}
        style={menuStyles}
      />
    </div>
  );
};

export default TopNavMenu;
