import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

const Loader = () => {
  return (
    <>
      <div style={{ padding: 10, margin: 10 }}>
        <LoadingOutlined /> Loading. Please wait ...
      </div>
    </>
  );
};

export default Loader;
