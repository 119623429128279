import connection from "../connection/connection";

// --------------------------------- Contacts  ---------------------------------
export const getAllContacts = async () => {
  try {
    const response = await connection.get("getAllContacts");
    if (response.data.status === "success") {
      return response.data.data;
    } else {
      console.error("Failed to fetch contacts: ", response.data.message);
      return null;
    }
  } catch (error) {
    console.error("Error fetching contacts:", error);
    return null;
  }
};
// -----------------------------------------------------------------------------

// --------------------------------- Groups ------------------------------------
export const getAllGroups = async () => {
  try {
    const response = await connection.get("getAllGroups");
    if (response.data.status === "success") {
      return response.data.data;
    } else {
      console.error("Failed to fetch groups: ", response.data.message);
      return null;
    }
  } catch (error) {
    console.error("Error fetching groups:", error);
    return null;
  }
};

export const createGroup = async ({ groupName, contacts }) => {
  try {
    const response = await connection.post("createGroup", {
      groupName,
      contacts,
    });

    if (response.data.status === "success") {
      return response.data;
    } else {
      console.error("Failed to create group: ", response.data.message);
      return null;
    }
  } catch (error) {
    console.error("Error creating group:", error);
    return null;
  }
};

export const sendGroupMessage = async ({ groupId, message }) => {
  try {
    const response = await connection.post("sendGroupMessage", {
      groupId,
      message,
    });

    if (response.data.status === 200) {
      return response.data;
    } else {
      console.error("Failed to create group: ", response.data.message);
      return null;
    }
  } catch (error) {
    console.error("Error creating group:", error);
    return null;
  }
};

export const sendMessageToGroups = async ({ groupIds, message }) => {
  try {
    const response = await connection.post("sendMessageToGroups", {
      groupIds,
      message,
    });

    if (response.data.status === 200) {
      return response.data;
    } else {
      console.error("Failed to create group: ", response.data.message);
      return null;
    }
  } catch (error) {
    console.error("Error creating group:", error);
    return null;
  }
};

// --------------------------------------------------------------------------------

// ----------------------------- Message Template ---------------------------------
export const getAllMessageTemplates = async () => {
  try {
    const response = await connection.get("getAllMessageTemplates");
    if (response.data.status === "success") {
      return response.data.data;
    } else {
      console.error("Failed to fetch message templates: ", response.data.message);
      return null;
    }
  } catch (error) {
    console.error("Error fetching message templates:", error);
    return null;
  }
};

export const createMessageTemplate = async ({ id, name, type, text, mediaurl }) => {
  try {
    const response = await connection.post("createMessageTemplate", {
      id,
      name,
      type,
      text,
      mediaurl,
    });

    if (response.data.status === "success") {
      return true;
    } else {
      console.error("Failed to create message template: ", response.data.message);
      return false;
    }
  } catch (error) {
    console.error("Error creating message template:", error);
    return false;
  }
};

export const updateMessageTemplate = async ({ _id, name, type, text, mediaurl }) => {
  try {
    const response = await connection.put("updateMessageTemplate/" + _id, {
      name,
      type,
      text,
      mediaurl,
    });
    if (response.data.status === "success") {
      return true;
    } else {
      console.error("Failed to update message template: ", response.data.message);
      return false;
    }
  } catch (error) {
    console.error("Error updating message template:", error);
    return false;
  }
};

export const deleteMessageTemplate = async ({ _id }) => {
  try {
    const response = await connection.delete("deleteMessageTemplate/" + _id);
    if (response.data.status === "success") {
      return true;
    } else {
      console.error("Failed to delete message template: ", response.data.message);
      return false;
    }
  } catch (error) {
    console.error("Error deleting message template:", error);
    return false;
  }
};
// --------------------------------------------------------------------------------

// ----------------------------- Groupsets ---------------------------------
export const getAllGroupsets = async () => {
  try {
    const response = await connection.get("getAllGroupsets");
    if (response.data.status === "success") {
      return response.data.data;
    } else {
      console.error("Failed to fetch groupset: ", response.data.message);
      return null;
    }
  } catch (error) {
    console.error("Error fetching groupset:", error);
    return null;
  }
};

export const createGroupset = async ({ id, name, groups }) => {
  try {
    const response = await connection.post("createGroupset", {
      id,
      name,
      groups,
    });

    if (response.data.status === "success") {
      return true;
    } else {
      console.error("Failed to create groupset: ", response.data.message);
      return false;
    }
  } catch (error) {
    console.error("Error creating groupser:", error);
    return false;
  }
};

export const updateGroupset = async ({ _id, name, groups }) => {
  try {
    const response = await connection.put("updateGroupset/" + _id, {
      name,
      groups,
    });
    if (response.data.status === "success") {
      return true;
    } else {
      console.error("Failed to update groupset: ", response.data.message);
      return false;
    }
  } catch (error) {
    console.error("Error updating groupset:", error);
    return false;
  }
};

export const deleteGroupset = async ({ _id }) => {
  try {
    const response = await connection.delete("deleteGroupset/" + _id);
    if (response.data.status === "success") {
      return true;
    } else {
      console.error("Failed to delete groupset: ", response.data.message);
      return false;
    }
  } catch (error) {
    console.error("Error deleting groupset:", error);
    return false;
  }
};
// --------------------------------------------------------------------------------

// --------------------------------- Sync Data  ------------------------------------
export const syncWAData = async () => {
  try {
    const response = await connection.get("syncWAData");
    if (response.data.status === "success") {
      return response.data;
    } else {
      console.error("Failed to sync: ", response.data.message);
      return null;
    }
  } catch (error) {
    console.error("Error sync:", error);
    return null;
  }
};
// -----------------------------------------------------------------------------

// --------------------------------- Schedule Job  ------------------------------------
// export const scheduleJob = async ({ groupIds, message, dateTime, repeat }) => {
//   try {
//     const response = await connection.post("scheduleJob", { groupIds, message, dateTime, repeat });
//     if (response.data.status === "success") {
//       return response.data;
//     } else {
//       console.error("Failed to sync: ", response.data.message);
//       return null;
//     }
//   } catch (error) {
//     console.error("Error sync:", error);
//     return null;
//   }
// };
export const scheduleJob = async ({ id, date, repeat, templateId, messageType, messageMediaUrl,messageText, groupsetId, groupIds }) => {
  try {
    const response = await connection.post("scheduleJob", { id, date, repeat, templateId, messageType, messageMediaUrl,messageText, groupsetId, groupIds });
    if (response.data.status === "success") {
      return response.data;
    } else {
      console.error("Failed to sync: ", response.data.message);
      return null;
    }
  } catch (error) {
    console.error("Error sync:", error);
    return null;
  }
};


// --------------------------------- Scheduled Jobs ---------------------------------
export const getScheduledJobs = async () => {
  try {
    const response = await connection.get("getScheduledJobs");
    if (response.data.status === "success") {
      return response.data.data;
    } else {
      console.error("Failed to fetch scheduled jobs: ", response.data.message);
      return null;
    }
  } catch (error) {
    console.error("Error fetching scheduled jobs:", error);
    return null;
  }
};
// -----------------------------------------------------------------------------



// -----------------------------------------------------------------------------
