import React from "react";
import ListContacts from "./ListContacts";

const Contacts = () => {
  return (
    <>
      <div>
        <h1>Contacts</h1>
        <ListContacts />
      </div>
    </>
  );
};

export default Contacts;
