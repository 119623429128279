import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { Button, Table } from "antd";
import { getAllGroups, getGroupMembers, sendGroupMessage } from "../../services/service";

const ListGroups = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [selectedGroupMembers, setSelectedGroupMembers] = useState([]);

  const columns = [
    {
      title: "Group",
      dataIndex: "name",
      key: "name",
    },
  ];

  useEffect(() => {
    setLoading(true);
    getAllGroups().then((grs) => {
      console.log("Grs:", grs);
      setAllGroups(grs ? grs : []);
      setLoading(false);
    });
  }, []);

  const tableRowOnClick = (record) => {
    setSelectedGroup(record);
    /*
    getGroupMembers({ groupId: record?.id }).then((data) => {
      setSelectedGroupMembers(data.data);
      setLoading(false);
    });
    */
  };

  const send_message_button_click = () => {
    console.log("selectedGroup", selectedGroup);

    sendGroupMessage({ groupId: selectedGroup.id, message: "This is Group Message Test" }).then((res) => {
      console.log("res:", res);
      setLoading(false);
    });
  };

  return (
    <>
    {loading && (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <Loader />
      </div>
    )}
  
    {!loading && (
      <div
        style={{
          padding: "20px",
          margin: "20px auto",
          maxWidth: "1200px",
          border: "1px solid rgb(0, 0, 0)",
          borderRadius: "8px",
          backgroundColor: "#fff",
          boxShadow: "0 22px 18px rgba(0, 0, 0, 0.1)",
        }}
      >
           <h1 style={{fontSize: "30px",
          fontWeight: "bold",
          color: "#0060ca",
          marginBottom: "20px",
          textAlign: "center",
          textTransform:"uppercase"}}>Groups List</h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <h2 style={{ fontSize: "24px", fontWeight: "bold", color: "#0060ca" }}>
            Total group(s): {allGroups.length}
          </h2>
          <Button
            onClick={() => navigate("/creategroup")}
            type="primary"
            style={{
              fontSize: "16px",
              backgroundColor: "teal",
              color: "white",
              border: "1px solid black",
            }}
          >
            Create Group
          </Button>
        </div>
  
        <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
          <div style={{ width: "40%" }}>
            <Table
              columns={columns.map((col) => ({
                ...col,
                ellipsis: true, // Handle long text with ellipsis
              }))}
              dataSource={allGroups}
              loading={loading}
              rowKey="name"
              scroll={{ y: 240 }}
              onRow={(record) => ({
                onClick: () => tableRowOnClick(record),
                style: { cursor: "pointer" },
              })}
              style={{
                border: "1px solid #f0f0f0",
                borderRadius: "8px",
                overflow: "hidden",
                width:"150%"
              }}
            />
          </div>
  
          {Object.keys(selectedGroup)?.length > 0 && (
            <div style={{ flex: 1 }}>
              <div style={{ marginBottom: "20px" }}>
                <h3
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "#4CAF50",
                    marginBottom: "10px",
                  }}
                >
                  {selectedGroup?.name}
                </h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <h5 style={{ fontSize: "16px", fontWeight: "bold", color: "#0060ca" }}>
                    Total member(s): {selectedGroup?.participants?.length}
                  </h5>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Button
                      type="primary"
                      style={{
                        fontSize: "14px",
                        backgroundColor: "lime",
                        color: "black",
                        border: "1px solid black",
                      }}
                    >
                      Add Member
                    </Button>
                    <Button
                      onClick={send_message_button_click}
                      type="primary"
                      style={{
                        fontSize: "14px",
                        backgroundColor: "lime",
                        color: "black",
                        border: "1px solid black",
                      }}
                    >
                      Send Message
                    </Button>
                  </div>
                </div>
              </div>
  
              <div
                style={{
                  maxHeight: "240px",
                  overflowY: "auto",
                  padding: "10px",
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                }}
              >
                {selectedGroup?.participants.map((participant, i) => (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: "10px",
                      margin: "5px 0",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#333",
                      backgroundColor: participant.isAdmin ? "#d5fad5" : "white",
                      borderRadius: "5px",
                    }}
                  >
                    <div>{participant.user}</div>
                    <div style={{ fontSize: "12px", color: "green" }}>
                      {participant.isAdmin && "(Admin)"} {participant.isSuperAdmin && "(Super Admin)"}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    )}
  </>
  
  );
};

export default ListGroups;
